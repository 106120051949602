import axios from "axios"
import { createEffect } from "effector"
import { defaultPath } from "./Auth"
import { getAccauntId } from "./InfoContext"

export const GetWebHook = createEffect(async()=>{
 
    const res = await axios.get(`${defaultPath}/api/moysklad/get_webhook/${getAccauntId()}`)
 
    return res.data
 })
export const CreateWebHook = createEffect(async()=>{

 
    await axios.post(`${defaultPath}/api/moysklad/create_webhook/${getAccauntId()}`,{ webhook:"material"})

    await axios.post(`${defaultPath}/api/moysklad/create_webhook/${getAccauntId()}`,{ webhook:"tech_card"})

    await axios.post(`${defaultPath}/api/moysklad/create_webhook/${getAccauntId()}`,{ webhook:"order"})
 
    
 })

 export const DeleteWebHook = createEffect(async()=>{

    await axios.post(`${defaultPath}/api/moysklad/delete_webhook/${getAccauntId()}`,{ webhook:"material"})

    await axios.post(`${defaultPath}/api/moysklad/delete_webhook/${getAccauntId()}`,{ webhook:"tech_card"})

    await axios.post(`${defaultPath}/api/moysklad/delete_webhook/${getAccauntId()}`,{ webhook:"order"})
    
 })