import React, { useEffect, useState } from 'react'
//import { $categories, GetCategories } from '../store/GetCategories'
import { $scklads, GetSklads } from '../store/GetSklads'
import { $LogPass, GetLogPas, UpdateLogPas } from '../store/LogPas'
import { $settings, GetSetting, SaveSetting, patchImport } from '../store/Settings'
import { useUnit } from 'effector-react'
import { $states, GetStatus } from '../store/GetStatus'
import { FirstLaunch } from '../store/FirstLaunch'
import { CreateWebHook, DeleteWebHook, GetWebHook } from '../store/WebHook'
import { CheckAmount } from '../store/CheckAmount'
import { message, Select } from 'antd'
import { $organizations, GetOrganizations } from '../store/Organisation'

const Main = () => {
    const sklads = useUnit($scklads)
    const organizations = useUnit($organizations)
    //const categories = useUnit($categories)
    const logPas = useUnit($LogPass)
    const states = useUnit($states)
    const settings = useUnit($settings)
    //const [product,setProduct] = useState('')
    //const [material,setMaterial] = useState('')
    const [skladMaterial,setSkladMaterial] = useState('')
    const [skladProduct,setSkladProduct] = useState('')
    const [statusCond,setStatusCond] = useState<string[]>([])
    const [organization,setOrganization] = useState('')
    //const [statusMc,setStatusMc] = useState('')
    //const [uom,setUom] = useState<boolean>(false)
    //const [materialLaunch,setMaterialLaunch] = useState(false)
    const [email,setEmail] = useState(logPas.cond_login ?? '')
    const [password,setPassword] = useState(logPas.cond_password ?? '')
    //const [importUom,setImportUom] = useState<any>('')
    //const [importMaterial,setImportMaterial] = useState<any>('')


    useEffect(()=>{
        GetSklads()
        GetStatus()
        GetOrganizations()
        GetSetting().then(async (res:any)=>{
            if(res){
                setSkladMaterial(res.material_store_id)
                setSkladProduct(res.product_store_id)
                setStatusCond(res.states.map((item:any)=> item.order_status_id))
                setOrganization(res.organization_id || organizations[0].id)
            }
        })
        if(!logPas){
            GetLogPas().then((res)=>{
                setEmail(res.cond_login )
                setPassword(res.cond_password)
            })
        }

    },[])

    const updateAuth = () => {
        UpdateLogPas({login:email,password})
        .catch(()=>message.error('Неверная пара логин пароль'))
    }


    const save =()=>{
        if(skladMaterial && statusCond && skladProduct && organization){
            const params ={
                skladMaterial: skladMaterial,
                skladProduct: skladProduct,
                status: statusCond,
                organization : organization
            }
            SaveSetting(params) 
        }else{
            message.error("Заполните поля")
        }
    }

    const deleteNomenclature = () => {
        DeleteWebHook().then(()=>{
            patchImport(false).then(()=>GetSetting())})
    }

    const importNomenclature = () => {
        FirstLaunch({ uom: true,stage: true,product: true,material: true }).then(() => {
            CreateWebHook().then(()=>{
              patchImport(true).then(()=>{
                  GetSetting();
                  //checkUomStatus();
              })
            })
          });
        /*FirstLaunch({ uom: true })
        FirstLaunch({ stage:true }).then(() => {
          CreateWebHook().then(()=>{
            patchImport(true).then(()=>{
                GetSetting();
                checkUomStatus();
            })
          })
        });
      
        const checkUomStatus = async () => {
          const res = await CheckAmount('uom');
          if (res.status === 100) {
            checkMaterialStatus();
          } else {
            setTimeout(() => {
              checkUomStatus();
            }, 3000);
          }
        };
      
        const checkMaterialStatus = async () => {
          const res = await Promise.all([
            CheckAmount('material'),
            CheckAmount('stage')
            ])
          if (res[0].status === 100 && res[1].status === 100) {
            FirstLaunch({ product: true });
          } else {
            setTimeout(() => {
              checkMaterialStatus();
            }, 3000);
          }
        };*/
      };

      const cinh = () =>{
        FirstLaunch({ uom: true,stage: true,product: true,material: true })
        //FirstLaunch({ stage:true })
        //FirstLaunch({ product: true })
        //FirstLaunch({ material: true })
      }

  return (
    <div className='ui-kit'>
        <div className="container">
            <div style={{display:'flex',justifyContent:"center", gap:'20px'}}>
                <div className='content'>
                    <label className='lable'>
                        Склад материалов
                        <select className="ui-select" name="ui-select"  value={skladMaterial} onChange={(e)=> setSkladMaterial(e.target.value)}>
                            { sklads && sklads.map((item:any,index:number)=>{
                                return (
                                    <option value={item.id}>{item.name}</option>
                                )
                            })}
                        </select>
                    </label> 
                    <label className='lable'>
                        Склад продукции
                        <select className="ui-select" name="ui-select" value={skladProduct} onChange={(e)=> setSkladProduct(e.target.value)}>
                            { sklads && sklads.map((item:any,index:number)=>{
                                return (
                                    <option value={item.id}>{item.name}</option>
                                )
                            })}
                        </select>
                    </label> 
                    <label className='lable'>
                        Организация
                        <select className="ui-select" name="ui-select" value={organization} onChange={(e)=> setOrganization(e.target.value)}>
                            { organizations && organizations.map((item:any,index:number)=>{
                                return (
                                    <option value={item.id}>{item.name}</option>
                                )
                            })}
                        </select>
                    </label> 

                    <label className='lable' >
                        Статус заказа для экспорта в Кондуктор
                        {states &&<Select
                          mode="multiple"
                          allowClear
                          value={statusCond}
                          style={{ width: '270px' }}
                          placeholder="Выберите"
                          onChange={(value: string[])=>setStatusCond(value)}
                          options={ states.map((item:any)=> {return{label:item.name,value:item.id}})}
                        />}
                    </label> 
                    <a href="#" className="ui-table__link">Инструкция</a>
                    <button className=" button button--success" onClick={save}>Сохранить настройки</button>

                    <br/>
                    { settings && !settings.active && <button className="button" onClick={importNomenclature}>Включить интеграцию</button> }
                    { settings && settings.active && <button className="button button--red" onClick={deleteNomenclature}>Отключить интеграцию</button> }
                    { settings && settings.active && <button className="button button" onClick={cinh}>Синхронизировать</button> }
                </div>
                <div style={{display:'flex',justifyContent:"center"}}>
                    <div className='log'>
                        <input className="ui-input" type="text" name="ui-input" placeholder="Почта" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                        <input className="ui-input" type="text" name="ui-input" placeholder="Пароль" value={password} onChange={(e)=>setPassword(e.target.value)}/>
                        <button className="button" style={{padding:'8px'}} onClick={updateAuth}>Изменить</button>
                    </div>
                </div>
            </div>
        </div>    
    </div>
  )
}

export default Main

